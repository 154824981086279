<template>
  <div class="raffles-element">

    <div class="container">
      <div class="row mt-5 mb-5">
      <h5 class="font-main-light-italic">All Raffles</h5>
        <div class="col-lg-12">
          <div class="mt-3">
            <ul class="nav nav-tabs font-main-italic" role="tablist">
              <li class="nav-item" role="presentation">
                <button @click="switchTab('new')" :class="{active: tab==='new'}" class="nav-link" aria-selected="true" role="tab">New</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="switchTab('ending')" :class="{active: tab==='ending'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Ending</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="switchTab('past')" :class="{active: tab==='past'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Over</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="switchTab('free')" :class="{active: tab==='free'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Free</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="switchTab('entered')" :class="{active: tab==='entered'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Entered</button>
              </li>                
              <li class="nav-item" role="presentation">
                <button @click="switchTab('created')" :class="{active: tab==='created'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Created</button>
              </li>
            </ul>
            <div id="myTabContent" class="tab-content">
              <div class="tab-pane fade show active" v-if="tab === 'new'" role="tabpanel">
                <div class="container mt-4 ms-2">
                  <div class="row">
                    <NewRafflesElement/>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="tab === 'ending'" role="tabpanel">
                <div class="container mt-4 ms-2">
                  <div class="row">
                    <EndingRafflesElement/>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="tab === 'past'" role="tabpanel">
                <div class="container mt-4 ms-2">
                  <div class="row">
                    <PastRafflesElement/>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="tab === 'free'" role="tabpanel">
                <div class="container mt-4 ms-2">
                  <div class="row">
                    <FreeRafflesElement/>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="tab === 'entered'" role="tabpanel">
                <div class="container mt-4 ms-2">
                  <div class="row">
                    <EnteredRafflesElement/>
                  </div>
                </div>
              </div>                  
              <div class="tab-pane fade show active" v-if="tab === 'created'" role="tabpanel">
                <div class="container mt-4 ms-2">
                  <div class="row">
                    <CreatedRafflesElement/>
                  </div>
                </div>
              </div>                                                              
                  </div>
              </div>
          </div>
      </div>
    </div>    
  </div>
</template>

<script>
import NewRafflesElement from "@/components/NewRafflesElement.vue"
import EndingRafflesElement from "@/components/EndingRafflesElement.vue"
import PastRafflesElement from "@/components/PastRafflesElement.vue"
import EnteredRafflesElement from "@/components/EnteredRafflesElement.vue"
import CreatedRafflesElement from "@/components/CreatedRafflesElement.vue"
import FreeRafflesElement from "@/components/FreeRafflesElement.vue"
// import NewRafflesGrid from "@/components/NewRafflesGrid.vue"

export default {
  name: 'RafflesElement',
  data () {
    return {
        tab: 'new'
    }
  },
  components: {
    NewRafflesElement,
    EndingRafflesElement,
    PastRafflesElement,
    EnteredRafflesElement,
    CreatedRafflesElement,
    FreeRafflesElement,
    // NewRafflesGrid,
  },
  methods: {
    switchTab (tab) {
        this.tab = tab
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
 