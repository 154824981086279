<template>

    <div class="card card-hover box-shadow main-card mt-3 mb-3" style="text-decoration: none!Important;">
      <a :href="`#raffle/${raffle.id}`">
      <div class="card-body text-center text-truncate pb-075">
        <h5 class="card-title text-truncate font-main-light fs-1-2 mb-1">
          <i v-if="raffle.status.hasOwnProperty('featured')" class="bi bi-patch-check" data-bs-toggle="tooltip" data-bs-placement="top" title="Promoted Raffle"></i>
          Raffle #{{ raffle.id }}
        </h5>
        <h6 class="card-title text-truncate font-main">
          <span v-show="raffle.editions > 1">{{ raffle.editions }}x </span> {{ tokenName }}  <span class="" v-show="raffle.added_prize > 0.01" style="">+ {{ raffle.added_prize/1000000 }} <span style="font-weight:bold;font-size:17px;">ꜩ</span> </span>
        </h6>
        <span class="badge bg-light badge-max-width text-truncate me-1"><i class="bi bi-card-image"></i> {{ ktName }} </span>
        <span class="badge bg-secondary badge-max-width text-truncate"><i class="bi bi-person-fill"></i> {{ formatAddress(raffle.creator) }}</span>
      </div>
      <div class="img-wrapper">
        <v-lazy-image :src="`${thumbnailUrl}`" class="d-block w-100"/>
      </div>
      <ul class="list-group list-group-flush text-center font-main">
        <li class="list-group-item item-sold">
        <i class="bi bi-ticket-detailed-fill pe-1"></i> {{ raffle.tickets_sold }}/{{ raffle.max_tickets }} SOLD</li>
        <li class="list-group-item item-floor">Floor <span class="fs-1-75"><img src="/Bootswatch_ Cyborg_files/images/tezos.png" class="pt-1 ps-1 pe-1 tezos-logo"></span> {{ ktFloor }}</li> 
        <li class="list-group-item item-enter">
          <div class="font-main-light-italic small mb-1">
            <i class="bi bi-clock-history pe-1"/>
            <span v-if="raffle.tickets_sold == raffle.max_tickets">Sold-Out</span>
            <span v-else-if="(Date.parse(raffle.end_date ) < new Date())">Raffle Over</span>
            <span v-else>
            <vue-countdown :time="getTime(raffle.end_date)" :interval="100" v-slot="{ days, hours, minutes, seconds }">
              <span v-show="days > 0">{{ days }}D:</span>
              <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H:{{ minutes }}M:{{ seconds }}S</span>
            </vue-countdown>
          </span>
          </div>
          <a :href="'#raffle/'+`${raffle.id}`" class="card-link"><button type="button" class="btn btn-primary w-100" style="height: 40px;padding-top: 0.1em;"><i class="bi bi-ticket-detailed-fill"></i> <span v-if="raffle.ticket_price/1000000 < 0.01"> Free</span><span v-else> {{ raffle.ticket_price/1000000 }} <span class="fs-1-25"> ꜩ</span></span></button></a>
        </li>   
      </ul>    
    </a>
  </div>

</template>

<script>
import { formatPkhString } from "../utils";
import VueCountdown from '@chenfengyuan/vue-countdown'
import VLazyImage from "v-lazy-image";
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';

export default {
  props: {
      raffle: Object,
  },
  components: {
    VueCountdown: VueCountdown,
    "v-lazy-image": VLazyImage,
  },
  data (){
    return{
      thumbnailUrl: '',
      tokenName: '',
    }
  },
  computed: {
    ...mapStores(useRaffleStore),

    ktName () {
      if (this.raffle.length == 0) return
      if (this.raffleStore.collections.length == 0) return
      if (this.raffleStore.collections[this.raffle.kt].length == 0) return
      return this.raffleStore.collections[this.raffle.kt].name 
    },
    ktFloor () {
      if (this.raffle.length == 0) return
      if (this.raffleStore.collections.length == 0) return
      if (this.raffleStore.raffles == 0) return
      if (this.raffle.kt === 'KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc' && this.raffle.token_id == 6 ) return 2
      var newCollections = JSON.parse ( JSON.stringify ( this.raffleStore.collections) )
      if ((Object.values(newCollections).filter(element => element.kt === this.raffle.kt).length == 0)) return 'N/A'
      return this.raffleStore.collections[this.raffle.kt].floor
    },
  },
    async mounted(){
      // getTokenMetaData
      if(this.raffle.kt !== "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS") {
        const data = await this.raffleStore.getTokenMetaData({ kt: this.raffle.kt, tokenId: this.raffle.token_id } );
        let shortlink = data[0].metadata.thumbnailUri.substring(7)
        if (this.raffle.kt === "KT1TJ53kra5YhkmR6oPS4c7YvtkoQGZShNpe" || this.raffle.kt === "KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc") { shortlink = data[0].metadata.displayUri.substring(7) }
        let url = "https://nftstorage.link/ipfs/"+shortlink
        if (this.raffle.kt === "KT1MQL8VjVQckk5A6uBfN9Qv2YUVJstG1CyH") { url = "https://nft-picture.playstables.io/nft/collection/001/horse/"+this.raffle.token_id+".jpg" }
        this.thumbnailUrl = url
        this.tokenName = data[0].metadata.name
        }
        if(this.raffle.kt === "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"){ this.thumbnailUrl = "https://app.tezos.domains/assets/img/logo_with_white_text.svg"; this.tokenName = "Domain: celcius.tez";}

  },
  methods: {

    // return tez.domain or shorten wallets
    formatAddress(pkh) {
      if (pkh === '') return 0
      var filtered = [...this.raffleStore.wallets].filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    }, 
   
   getTime(date) {
      const datetime = new Date(date)
      if (datetime.getTime() - new Date().getTime() < 0) return 0
      return datetime !== 'Invalid Date' ? datetime.getTime() - new Date().getTime() : null
   },
}
}
</script>

<style scoped>
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.np-ht {
  height: 600px;
}

.np-header {
  padding: 30px;
  background: #eee;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.v-lazy-image {
  filter: blur(20px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
