<template>
  <!--FAQModal-->
  <div class="modal fade" :class="{show: show}" id="faqModal" tabindex="-1" aria-labelledby="#faqModal" :aria-hidden="!show" :aria-modal="show" :style="{display: show ? 'block' : 'none' }">
      <div class="modal-dialog">
        <div class="modal-content font-main modal-border box-shadow">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">F.A.Q.</h1>
            <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <h4>What is tzRaffles?</h4>
              <div class="mb-2 muted small font-main-light ">
                tzRaffles is a decentralized Tezos raffle platform where everyone can host raffles for their NFTs from whitelisted NFT-Collections.
              </div>
          </div>           
          <div class="modal-body">
              <h4>How to raffle?</h4>
              <div class="mb-2 muted small font-main-light ">
                1) Connect wallet<br>
                2a) Sellers: Choose NFT & start raffle<br>
                2b) Buyers: Enter raffles<br>
                3) Raffle ends with time over or sold-out<br>
                4) Contract draws random winner ticket<br>
                5a) Sellers: Ticket sales get sent automatically<br>
                5b) Buyers: Raffle Winner(s) can claim their NFT(s)<br><br>
                A winner will always be drawn. Even when there is only 1 ticket sold. There is no way to cancel a raffle.
              </div>
          </div>          
          <div class="modal-body">
              <h4>Is it safe?</h4>
              <div class="mb-2 muted small font-main-light ">
                tzRaffles is in early beta mode. There might be some bugs. Please use with caution. 
              </div>
          </div>
          <div class="modal-body">
              <h4>What happens to my NFT?</h4>
              <div class="mb-2 muted small font-main-light ">
                When starting a raffle, your NFT(s) & the ticket sales will be held safely in a smart contract custody wallet until the winners are drawn.
              </div>
          </div>    
          <div class="modal-body">
              <h4>How is it random?</h4>
              <div class="mb-2 muted small font-main-light ">
                We use a Oracle that generates random seeds every few blocks. When a raffle is over the seed of 2 epochs in the future seed will be taken and the winner based on that particular seed is calculated. (This is why you need to wait a few minutes after a raffle until a winner is calculated)
              </div>
          </div>       
          <div class="modal-body">
              <h4>Are there fees?</h4>
              <div class="mb-2 muted small font-main-light ">
                Yes: 5% ticket sales & optional Featured Fee (0.15 Tez)
              </div>
          </div>   
          <div class="modal-body">
              <h4>How to create a healthy raffle?</h4>
              <div class="mb-2 muted small font-main-light ">
                If you are hosting a raffle, make sure to use the limit of tickets to price your tickets. Try to make the cost of each ticket * total tickets = just a little above the NFT floor in terms of value. This guarantees that the host of the raffle will not abuse it by buying many tickets worth above the NFT floor and making it more attractive to buyers.<br>
                Example: If you sell a NFT, try to make the volume of the raffle ~20% higher than the floor price. Let's say a Ottez has a floor of 40 TEZ, then you should aim for a 50 Tez max volume = 50 Max Tickets and Ticket Price 1 Tez.
              </div>
          </div>  
          <div class="modal-body">
              <h4>I didn't profit from my raffle, can I have a refund?</h4>
              <div class="mb-2 muted small font-main-light ">
                No, we do not guarantee profits on our raffles. Raffles are supposed to be fun and you have to agree with the terms and conditions to host one.
              </div>
          </div>            
          <div class="modal-body">
              <h4>A raffle looks suspicious, can you delete it?</h4>
              <div class="mb-2 muted small font-main-light ">
                No, tzRaffles is a decentralized platform. We cannot interfere with raffles because we are not the hosts. If you think a raffle looks suspicious you can report it on our discord server by opening a ticket and we can flag it. We also can't refund any tickets.
              </div>
          </div>                                 
          <div class="modal-footer">
            <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ModalsFaq',
  props: {
    show: Boolean
  }
}
</script>