
<template>
      <button v-if="raffleStore.pkh" @click="profile" class="is-connected btn btn-primary my-2 my-sm-0 box-shadow">
        {{ formatAddress(raffleStore.pkh) }} <i class="bi bi-caret-down-fill"></i>
      </button>
      <button v-else @click="connect" class="btn btn-primary my-2 my-sm-0 box-shadow"><i class="bi bi-credit-card"></i> Connect Wallet</button>
      <div class="modal fade" :class="{show: show}" :aria-hidden="!show" :aria-modal="show" :style="{display: show ? 'block' : 'none' }">
        <div class="modal-dialog">
          <div class="modal-content font-main modal-border box-shadow">
            <div class="modal-header text-center">
              <button v-if="raffleStore.pkh" @click="disconnect" class="is-connected btn btn-primary my-2 my-sm-0 box-shadow">Disconnect Wallet</button>
              <button @click="close()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-start">
              <h5 class="font-main">Last Raffles Won:</h5>
              <table class="table table-hover text-sm">
                <tbody>
                  Coming soon                           
                </tbody>
              </table>
              <h5 class="font-main">Last Raffles Entered:</h5>
              <table class="table table-hover text-sm">
                <tbody>
                  <tr v-for="ticket in tickets" :key="ticket" >
                    <th scope="row"><a :href="'#raffle/'+`${ticket[0].nat}`"><i class="bi bi-postage-heart-fill"></i> Raffle #{{ ticket[0].nat }} </a></th>
                    <td> {{ Math.max(1, ticket[1].length) }} Tickets</td>              
                  </tr>                                
                </tbody>
              </table>
              <h5 class="font-main">Last Raffles Created:</h5>
              <table class="table table-hover text-sm">
                <tbody>
                  <tr v-for="raffle in raffleStore.rafflesCreated" :key="raffle" >
                      <th scope="row"><a :href="'#raffle/'+`${raffle.id}`"><i class="bi bi-postage-heart-fill"></i> Raffle #{{ raffle.id }} </a></th>
                      <td>{{ raffle.tickets_sold }}/{{ raffle.max_tickets }} Tickets</td>              
                  </tr>                                
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { formatPkhString } from "../utils";
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: " SyncButtonElement",
  data () {
        return {
            show: false,
            tickets: [],
        }
    },
  computed: {
    ...mapStores(useRaffleStore),
  },
  setup(){
    
  },
  methods: {
    connect() {
      this.raffleStore.connectWallet();
    },
    disconnect() {
      this.raffleStore.disconnectWallet();
      this.close();
    },
    async profile() {
      const store = useRaffleStore()
      let list = await store.listAllMyRaffleTickets()
      this.tickets = list
      this.show = true;
    },
    close(){
      this.show = false;
    },
    formatAddress(pkh) {
      return formatPkhString(pkh);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>