<template >
  <div class="featured-raffles-element">
       
    <div class="container mt-5 mb-5"> 
      <h5 class="font-main-light-italic">Featured Raffles</h5>
      <div class="row">
          <Flicking class="flicking-container" :options="{ align: 'prev' }" :plugins="plugins" v-if="dataReady">
            <RaffleCard v-for="raffle in raffleStore.rafflesFeatured" :raffle="raffle" :key="Number(raffle.id)" class="card card-hover box-shadow main-card mt-3 mb-3" />
            <template #viewport>
              <span class="flicking-arrow-prev is-circle d-none d-lg-block"></span>
              <span class="flicking-arrow-next is-circle d-none d-lg-block"></span>
            </template>
          </Flicking>
          <Flicking :options="{ align: 'prev' }" v-else>
            <RaffleCardSkeleton v-for="index in 10" :key="index" class="card card-hover box-shadow main-card mt-3 mb-3" />
          </Flicking>
      </div>
    </div>

    <hr>

  </div>
</template>

<script>
import Flicking from "@egjs/vue3-flicking"
import RaffleCard from "./RaffleCard"
import RaffleCardSkeleton from "./RaffleCardSkeleton"
import { Arrow } from "@egjs/flicking-plugins";
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: 'FeaturedRafflesElement',
  props: {
    msg: String,
  },
  components: {
    Flicking,
    RaffleCard,
    RaffleCardSkeleton,
  },
  data() {
    return {
    plugins: [new Arrow()],
    dataReady: false,
    }
  }, 
  mounted(){
    this.dataReady = true;
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
}
</script>
 