<template>
  <div class="main-element">

    <div class="container">
      <div class="page-header" id="banner">
        <div class="row">
          <div class="col-xl-8 col-lg-12 mb-3">
            <h2 class="font-main text">Sell NFTs by creating <span class="font-main animated-text">your own Raffle</span> or enter <span class="font-main animated-text"> ongoing Raffles</span> to win NFTs </h2> <!-- to win Tezos NFTs -->    
            <h6 class="text-muted"><i class="bi bi-exclamation-octagon"></i> This is the old beta website. Please go to www.tzraffles.fun for the live site.</h6>      
          </div>
        </div>
      </div>
    </div>

    <hr>
     
  </div>
</template>

<script>
export default {
  name: 'MainElement',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.animated-text {
	background: linear-gradient(45deg, #00f0ff, #d800ff);
	background-size: 300%;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 900;
	-webkit-background-clip: text;
  background-clip: text; /* Added manually */
	-webkit-text-fill-color: transparent;
	animation: animated_text 5s ease-in-out infinite;
	-moz-animation: animated_text 5s ease-in-out infinite;
	-webkit-animation: animated_text 5s ease-in-out infinite;
}

@keyframes animated_text {
	0% { background-position: 0px 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0px 50%; }
}
</style>
 