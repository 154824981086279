import { defineStore } from 'pinia'
import axios from "axios"; 
import { TezosToolkit, OpKind } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { ColorMode, NetworkType } from "@airgap/beacon-sdk";
import { CONTRACT_ADDRESS_RAFFLES } from "@/utils";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { formatPkhString } from "../utils";

// Set the network
const network = { type: NetworkType.MAINNET }; //  MAINNET
const tzktapi = `https://api.tzkt.io`;
const slice = 9;

// initialiize the SDK
const Tezos = new TezosToolkit("https://mainnet.smartpy.io"); 

const wallet = new BeaconWallet({
  name: "tzRaffles",
  iconUrl: "https://beta.tzraffles.fun/Bootswatch_%20Cyborg_files/images/preview.png",
  preferredNetwork: network.type,
}); // Takes the same arguments as the DAppClient constructor

Tezos.setWalletProvider(wallet);

// setting the color mode for beacon wallet
wallet.client.setColorMode(ColorMode.DARK);

const getContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS_RAFFLES);

/* SignalR Code */
const signalR = require("@microsoft/signalr");

const connection = new signalR.HubConnectionBuilder()
.withUrl("https://api.tzkt.io/v1/ws")
.build();

connection.on("events", (msg) => { 
    if ((msg.data[0].tag === 'tickets_bought' )) {
       console.log(msg);
       var wallet = formatPkhString(msg.data[0].payload.address)
       toast.info(wallet+" bought "+msg.data[0].payload.quantity+" ticket(s) to Raffle #"+msg.data[0].payload.raffle_id, {
        autoClose: 4000,
        theme: 'dark',
        icon: "🎫",
      }); 
    }
    if ((msg.data[0].tag === 'raffle_created' )) {
      console.log(msg);
      toast(formatPkhString(msg.data[0].payload.address)+" created Raffle #"+msg.data[0].payload.nat+" with " +msg.data[0].payload.max_tickets+" Tickets", {
       autoClose: 4000,
       theme: 'dark',
       icon: "🎟️",
     }); 
   }
  });

// auto-reconnect
// connection.onclose(init);

export const useRaffleStore = defineStore('raffle', {
    state: () => ({
    pkh: "",
    connected: false,
    loading: false,
    raffles: [],
    wallets:[],
    collections:[],
    events:[],
    }),
    getters: {
    raffleCount(state) {
    return state.raffles.length
    },
    rafflesFeatured(state) {
        let today = new Date().getTime() - 15*60*1000
        let sorted = [...state.raffles].sort(function(a, b) {   var c = new Date(a.end_date);    var d = new Date(b.end_date);    return c-d;   });
        return sorted.filter(element => (new Date(element.end_date ).getTime() > today && element.winning_tickets.length == 0 && element.status.featured)).slice(0,slice)
    },
    rafflesNew(state) {
        return [...state.raffles].reverse().slice(0,slice)
    },
    rafflesFree(state) {
        let sorted = [...state.raffles].sort(function(a, b) {   var c = new Date(a.end_date);    var d = new Date(b.end_date);    return c-d;   });
        let today = new Date().getTime()
        var rafflelist =  sorted.filter(element => (new Date(element.end_date ).getTime() > today && element.winning_tickets.length == 0 && (element.max_tickets !== element.tickets_sold) && element.ticket_price/1000000 < 0.01 && element.max_per_wallet < 4))
        return rafflelist.slice(0,slice)
    },
    rafflesEnding(state) {
        let sorted = [...state.raffles].sort(function(a, b) {   var c = new Date(a.end_date);    var d = new Date(b.end_date);    return c-d;   });
        let today = new Date().getTime()
        var rafflelist =  sorted.filter(element => (new Date(element.end_date ).getTime() > today && element.winning_tickets.length == 0 && (element.max_tickets !== element.tickets_sold) ))
        return rafflelist.slice(0,slice)
    },
    rafflesPast(state) {
        let today = new Date().getTime()
        let sorted = [...state.raffles].sort(function(a, b) {   var c = new Date(a.end_date);    var d = new Date(b.end_date);    return d-c;   });
        let rafflelist =  sorted.filter(element => new Date(element.end_date).getTime() < today || element.winning_tickets.length > 0)
        return rafflelist.slice(0,slice)
    },
    async rafflesEntered(state) {
        const list = [...state.raffles].reverse()
        // fetch all my Tickets
        let list1 = await this.listAllMyRaffleTickets()
        this.tickets = list1
        let myRaffleIds = []
        // create array with all my entered raffle ids
        list1.forEach(element => myRaffleIds.push(element[0]['nat']));
        // filter raffle list with only my entered raffle ids
        return list.filter(element => myRaffleIds.includes(element['id']))
    },
    rafflesCreated(state) {
        let raffleslist = [...state.raffles].filter(element => element.creator === state.pkh) // this.raffles.pkh)
        return raffleslist.reverse().slice(0,25)
    },
    rafflesNewFiltered(state) {
        return (kt) => (
            [...state.raffles].filter( raffles =>  raffles.kt == kt ).reverse().slice(0,slice)
        )
    },
    },
    actions: {

    // Subscribe Event Init

    async init() {
        // open connection
        await connection.start();
        // subscribe to head
        // await connection.invoke("SubscribeToHead");
        // subscribe to Events
        await connection.invoke("SubscribeToEvents", {
            contract: 'KT1S5Ft2c3sVwupSn8LRfHGZzyDxcTZxMjok',
        });
    },
        

    // Wallet Stuff:

   /**
   * @description requests permission to connect to the network
   */
    async connectWallet() {
        try {
        await wallet.requestPermissions({
            network: network,
        });
        this.checkWalletConnection();
        } catch (error) {
        console.log(error);
        }
    },
    /**
     * @description Check if the wallet is connected, updates the pkh in state if connected
     */
    async checkWalletConnection() {
        try {
        const activeAccount = await wallet.client.getActiveAccount();

        if (activeAccount) {
            // If defined, the user is connected to a wallet.
            this.pkh = activeAccount.address;
            this.connected = true;
        } else {
            this.pkh = '';
            this.connected = false;
        }   
        } catch (error) {
        console.log(error);
        }
    },
    /**
     * @description disconnects the wallet connection to the dApp
     */
    async disconnectWallet() {
        await wallet.clearActiveAccount();
        this.checkWalletConnection();
        //state.pkh = '';
    },

    // Set States
    async setRaffles() {
        const offset = 0
        const limit = 1000
        const { data } = await axios.get(`${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/raffles/keys?active=true&select.values=key,value&offset=${offset}&limit=${limit}`)  
        this.raffles = data.map(([id, value]) => ({id, ...value}))
        console.log(this.raffles)
        return
        },
    async setEvents() {
        const offset = 0
        const limit = 1000
        const { data } = await axios.get(`${tzktapi}/v1/contracts/events?contract=${CONTRACT_ADDRESS_RAFFLES}&offset=${offset}&limit=${limit}`)  
        this.events = data
        console.log(this.events)
        return
        },
    async setCollections() {
        const res = await fetch('wl-data.json')
        const data = await res.json()
        this.collections = data
        return
    },
    async setWallets() {
        const res = await fetch('wallet-domains.json')
        const data = await res.json()
        this.wallets = data
        return
    },
    // List Stuff
    async getRaffleDetails(raffleId) {
        const { data } = await axios.get(`${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/raffles/keys?active=true&select.values=key,value&key=${raffleId}`)
        return data.map(([id, value]) => ({id, ...value}))
      },
    async listMyCurrentRaffleTickets(raffleId) {
        if (!this.pkh || raffleId === '') return []
        const offset = 0
        const limit = 500
        const { data } = await axios.get(`${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/tickets/keys?active=true&select.values=value&offset=${offset}&limit=${limit}&key.address=${this.pkh}&key.nat=${raffleId}`)
        return data
    },
    async listAllMyRaffleTickets() {
        console.log('userAddress:', this.pkh)
        //if (!this.pkh) return []
        const offset = 0
        const limit = 1500
        const { data } = await axios.get(`${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/tickets/keys?active=true&select.values=key,value&offset=${offset}&limit=${limit}&key.address=${this.pkh}`)
        return data
    },
    async listCurrentRaffleTickets(raffleId) {
        if (raffleId === '') return []
        //console.log('userAddress:', this.pkh)
        const offset = 0
        const limit = 1500
        const { data } = await axios.get(`${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/tickets/keys?active=true&select.values=key,value&offset=${offset}&limit=${limit}&key.nat=${raffleId}`)
        return data
    },
    async listMyEvents() {
        const offset = 0
        const limit = 1000
        const { data } = await axios.get(`${tzktapi}/v1/contracts/events?contract=${CONTRACT_ADDRESS_RAFFLES}&offset=${offset}&limit=${limit}`)  
        this.events = data
        console.log(this.events)
        return
        },
    async getTokenMetaData({ kt, tokenId }){
        if (kt === "KT1TJ53kra5YhkmR6oPS4c7YvtkoQGZShNpe"){ kt ='KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc'; tokenId = 0}
        const { data } = await axios.get(`https://api.mainnet.tzkt.io/v1/tokens?contract=${kt}&tokenId=${tokenId}`)  
        return data 
      },
      // Raffle Actions
      async createRaffle({ kt, tokenId, ticketPrice, maxTickets, raffleLengthDays, editions, maxEntries, featured, addTezToPrizePool }) {
        try {
            if (!this.connected) await this.connectWallet()
    
            const contract = await getContract()
            const storage = await contract.storage()
            // console.log(storage)
            const featureFee = storage.config.feature_fee.toNumber()
            const fa2 = await Tezos.wallet.at(kt)
            const max_per_wallet = maxEntries
    
            const batch = await Tezos.wallet.batch([
                {
                    kind: OpKind.TRANSACTION,
                    ...fa2.methods.update_operators([
                        {
                            add_operator: {
                                owner: this.pkh,
                                operator: CONTRACT_ADDRESS_RAFFLES,
                                token_id: tokenId
                            }
                        }
                    ]).toTransferParams()
                },
                {
                    kind: OpKind.TRANSACTION,
                    ...contract.methodsObject.create_raffle({
                        days: raffleLengthDays,
                        editions,
                        feature: featured,
                        kt,
                        max_per_wallet,
                        max_tickets: maxTickets,
                        ticket_price: ticketPrice * 1000000, // to mutez
                        token_id: tokenId
                    }).toTransferParams(),
                    amount: (featured ? featureFee : 0) + (addTezToPrizePool * 1000000),
                    mutez: true
                }
              ]);
            const op = await batch.send()
            await op.confirmation(1)
            return true
        } catch (error) {
            console.log(error);
        }
      },
      async buyTickets({ quantity, raffleId ,ticketPrice }) { 
        try {
            if (!this.connected) await this.connectWallet()
            const contract = await getContract()
            const op = await contract.methods.buy_tickets(quantity, raffleId).send({ amount: (quantity * ticketPrice * 1000000).toFixed(2) , mutez: true})
            await op.confirmation(1)
        } catch (error) {
            console.log(error);
        }
      },
      async drawRaffle({ raffleId }) { 
        try {
            if (!this.connected) await this.connectWallet()
            const contract = await getContract()
            const op = await contract.methods.draw(raffleId).send()
            await op.confirmation(1)
        } catch (error) {
            console.log(error);
        }
      },
      async checkOutcome({ raffleId }) { 
        try {
            if (!this.connected) await this.connectWallet()
            const contract = await getContract()
            const op = await contract.methods.check_outcome(raffleId).send()
            await op.confirmation(1)
        } catch (error) {
            console.log(error);
        }
      },
      async claimPrize({ raffle_id, ticket_id }) { 
        try {
            if (!this.connected) await this.connectWallet()
            const contract = await getContract()
            const op = await contract.methods.claim_prize(raffle_id, ticket_id).send()
            await op.confirmation(1)
        } catch (error) {
            console.log(error);
        }
      },
    addRaffle(raffle) {
        this.raffles.push(raffle)
    },
    }
});