import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueConfetti from 'vue-confetti';

createApp(App)
    .use(createPinia())
    .use(Vue3Toasity)
    .use(VueConfetti)
    .mount('#app')