<template>
  <!--Navbar-->
  <div class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark font-main">
      <div class="container">
      <a href="" class="navbar-brand"><img class="navlogo" src="Bootswatch_ Cyborg_files/images/tzraffleslogo-nobr.png"></a>
      <button class="navbar-toggler" @click.prevent="navCollapse = !navCollapse" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse text-center" :class="{collapse: !navCollapse}" id="navbarResponsive">
          <ul class="navbar-nav">
            <!-- 
            <li class="nav-item">
                <a class="nav-link" href="#newRaffleModal">+ New Raffle</a>
            </li>

            -->
            <li class="nav-item">
                <a class="nav-link" href="#faqModal">+ FAQ</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-md-auto">
            <li class="nav-item">
              <a href="https://discord.gg/JPC2WND2PR"><i class="bi bi-discord fs-5 " style="color:#7289DA;"></i></a> 
            </li>
            <li class="nav-item">
              <a href="https://twitter.com/tzRaffles"><i class="bi bi-twitter fs-5 pe-3 ps-3"></i></a>
            </li>
          <li class="nav-item">
            <SyncButtonElement/>
          </li>
          </ul>
      </div>
      </div>
  </div>  
</template>

<script>
import SyncButtonElement from "./SyncButtonElement.vue";

export default {
  name: " NavHeader",
  components: {
    SyncButtonElement,
  },
  data(){
  return {
    navCollapse: false,
  }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
