<template>
  <div class="ModalElement">
    <ModalsFaq @close="onModalClose" v-if="modal === 'faqModal'" :show="modal === 'faqModal'"/>
    <ModalsNewRaffle @close="onModalClose" v-if="modal === 'newRaffleModal'" :show="modal === 'newRaffleModal'"/>
    <ModalsRaffleDetail @close="onModalClose" v-if="modal === 'raffle'" :show="modal === 'raffle'" :raffle="raffleId" :key="raffleStore.pkh" />
  </div>
</template>

<script>
import ModalsRaffleDetail from './ModalsRaffleDetail.vue';
import ModalsNewRaffle from './ModalsNewRaffle.vue';
import ModalsFaq from './ModalsFaq.vue';
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
    name: "ModalsContainer",
    components: {
    ModalsRaffleDetail,
    ModalsNewRaffle,
    ModalsFaq,
    },
    computed: {
        ...mapStores(useRaffleStore)
    },
    data () {
        return {
            modal: '',
            raffleId: '',
        }
    },
    mounted () {
        this.onURL(window.location.href)
        window.addEventListener('hashchange', (e) => {
            this.onURL(e.newURL)
        })
    },
    methods: {
        onURL (url) {
            if (url.includes("#")){
            const [, hash] = url.split('#')
            const [modal, id] = hash.split('/')
            this.raffleId = Number(id) || 0
            this.modal = modal || ''
            }
        },
        onModalClose () {
            window.location.hash = ''
        }
    },
}
</script>