<template>
  <div id="app">
    <Header/>
    <MainElement/>
    <Modals/>
    <FeaturedRafflesElement/>  
    <RafflesElement/>      
    <FooterElement/>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Modals from '@/components/Modals.vue'
import MainElement from '@/components/MainElement.vue'
import FeaturedRafflesElement from '@/components/FeaturedRafflesElement.vue'
import RafflesElement from '@/components/RafflesElement.vue'
import FooterElement from '@/components/FooterElement.vue'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: "App",
  components: {
    Header,
    MainElement,
    FeaturedRafflesElement,
    RafflesElement,
    Modals,
    FooterElement, 
  },
  setup(){
    const store = useRaffleStore()
    store.checkWalletConnection();
    store.setRaffles()
    store.setWallets()
    store.setCollections()
    store.init()
  },
  mounted() {

  },
  methods: {

  }, 
};
</script>

<style>
</style>
