<template>
  <div class="modal fade" :class="{show: show}" id="newRaffleModal" tabindex="-1" aria-labelledby="#newRaffleModal" :aria-hidden="!show" :aria-modal="show" :style="{display: show ? 'block' : 'none' }">
    <div class="modal-dialog">
      <div class="modal-content font-main modal-border box-shadow">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Start New Raffle</h1>
          <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6 class="text-warning small"><i class="bi bi-exclamation-octagon"></i> 
            We are currently operating in Beta. You can find the tokenid at the Objkt site of the nft under Properties: Token ID.
          </h6>      
          <form>
          <!-- 
            <div class="">
              <label for="raffle-contract-id" class="col-form-label">Collection (Contract):</label>
                <div class="input-group">                 
                  <span class="input-group-text"><i class="bi bi-123"></i></span>
                  <input type="text" class="form-control sm" id="raffle-contract-id" v-model.trim="form.kt">
                </div>                                
            </div>
          -->
          <div class="">
              <label for="raffle-contract-id" class="col-form-label">Collection:</label>
                <div class="input-group">                 
                  <span class="input-group-text"><i class="bi bi-123"></i></span>
                  <select class="form-select sm" id="raffle-contract-id" aria-label="Default select example" v-model.trim="form.kt">
                    <option v-for="(collection, index) in raffleStore.collections" :key="index" :value="`${collection.kt}`" :selected="index[0]" :class="{ 'd-none': collection.kt === 'KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc' && raffleStore.kt !== 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2'}">{{ collection.name }}</option>
                  </select>
                </div>                                
            </div>
            <div class="">
              <label for="raffle-token-id" class="col-form-label">Token ID:</label>
                <div class="input-group">                 
                  <span class="input-group-text"><i class="bi bi-1-square"></i></span>
                  <input type="text" class="form-control sm" id="raffle-token-id" v-model="form.tokenId">
                </div>                                   
            </div>
            <div class="">
              <label for="raffle-tickets-max" class="col-form-label">Tickets:</label>
                <div class="input-group">                 
                  <span class="input-group-text"><i class="bi bi-ticket-detailed-fill"></i></span>
                  <input type="number" min="2" max="1000" class="form-control sm" id="raffle-tickets-max" v-model="maxTickets" >
                  <div class="input-group-append">
                      <span class="input-group-text" style="width:60px;">Max</span>
                  </div>
                </div>                             
            </div>
            <div class="">
              <label for="raffle-price" class="col-form-label">Ticket Price:</label>              
                <div class="input-group">                 
                  <span class="input-group-text">ꜩ &nbsp;</span>
                  <input type="number" min="0" max="100" step="1" class="form-control sm" id="raffle-price" v-model="form.ticketPrice">
                  <div class="input-group-append">
                      <span class="input-group-text text-center" style="width:60px;"> Tez</span>
                  </div>                  
                </div>
            </div>
            <div class="">
              <label for="raffle-length" class="col-form-label">Length:</label>
                <div class="input-group">                 
                  <span class="input-group-text"><i class="bi bi-clock-history"></i></span>
                  <input type="number" min="1" max="10" step="1" class="form-control sm" id="raffle-length" v-model="form.raffleLengthDays">
                    <div class="input-group-append">
                      <span class="input-group-text" style="width:60px;">Days</span>
                    </div>
                </div>                    
            </div>
            <div class="mt-4 font-main-light-italic">         
                <button class="btn btn-primary" @click.prevent="optional = !optional" aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-arrows-expand"></i> </button>  &nbsp;Show Optional 
            </div>
            <div :class="{collapse: !optional}" id="collapseExample">
              <div class="mt-3">
              <div class="form-group">
                    <label for="raffle-tez" class="col-form-label">Editions (=Winners):</label>
                  <div class="input-group">                 
                    <span class="input-group-text"><i class="bi bi-trophy"></i></span>
                    <input type="number" min="1" max="100" step="1" class="form-control sm" id="raffle-tez" v-model="form.editions">
                  </div>
                </div>              
            </div>     
              <div class="">
                <div class="form-group">
                    <label for="raffle-tickets-max-per-wallet" class="col-form-label sm">Max Tickets per Wallet (Calculated from Max Tickets):</label>
                    <div class="input-group">                 
                      <span class="input-group-text"><i class="bi bi-ticket-perforated-fill"></i></span>
                      <input v-model="maxEntries" type="number" min="1" max="100" step="1" class="form-control sm" id="raffle-tickets-max-per-wallet">
                      <div class="input-group-append">
                    </div>
                    </div>

                  </div>
              </div>
              <div class="">
                <div class="form-group">
                      <label for="raffle-tez" class="col-form-label sm">Add Tez to Prize Pool:</label>
                    <div class="input-group">                 
                      <span class="input-group-text">ꜩ &nbsp;</span>
                      <input v-model="form.addTezToPrizePool" type="number" min="0" max="2000" step="1" class="form-control sm" id="raffle-tez">
                    </div>
                </div>
              </div>
              <!--
              <div class="">
              <div class="form-group">
                    <label for="raffle-currency" class="col-form-label">Set custom raffle currency contract:</label>
                  <div class="input-group">                 
                    <span class="input-group-text">$ &nbsp;</span>
                    <input type="text"  step="1" class="form-control sm" id="raffle-currency">
                  </div>
                </div>              
              </div>
              -->                            
            </div>
            <div class="form-check form-switch mt-3 font-main-light small">
                <input v-model="form.featured" class="form-check-input sm" type="checkbox" id="raffle-featured">
                <label class="form-check-label small" for="raffle-featured">Featured Raffle (0.15ꜩ fee)</label>
            </div>  
          </form>     
          <div class="mt-3 muted small font-main-light-italic">
                  By creating this raffle I understand that I am using this product in Beta at my own risk and I read the <a href="#faqModal" target="_blank" class="text-decoration-none">Terms & Services & FAQ</a>. Any losses incurred are my own responsibility. 
          </div>
        </div>
        <div class="modal-footer">
          <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button @click="onSubmit" type="button" class="btn btn-primary">
          <span v-if="working">
            <self-building-square-spinner
            :animation-duration="6000"
            :size="25"
            :color="'#23c2cc'"
            />
          </span>
          <span v-else>Create Raffle</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import { SelfBuildingSquareSpinner } from 'epic-spinners'
 import { mapStores } from 'pinia'
 import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: 'ModalsNewRaffle',
  props: {
    show: Boolean
  },
  components:{
    SelfBuildingSquareSpinner,
  },
  data () {
    return {
        optional: false,
        working: false,
        collections: [],
        x_maxPercent: 25,
        form: {
            kt: '',
            tokenId: 0,
            ticketPrice: 1,
            maxTickets: 100,
            raffleLengthDays: 3,
            editions: 1,
            maxEntries: 1,
            featured: true,
            addTezToPrizePool: 0
        }
    }
  },
  computed: {
    ...mapStores(useRaffleStore),
    maxTickets: {
        get () {
            return this.form.maxTickets || 1
        },
        set (value) {
            this.form.maxTickets = value || 1
            this.form.maxEntries = Math.ceil(value / 100 * this.x_maxPercent)
        }
    },
    maxEntries: {
        get () {
            return this.form.maxEntries || 1
        },
        set (value) {
            this.form.maxEntries = value || 1
            this.x_maxPercent = this.form.maxEntries / this.form.maxTickets * 100
        }
    }
  },
  methods: {
    async onSubmit () {
        try {
            this.working = true    
            this.raffleStore.createRaffle(this.form)
            await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
            console.log(e)
        } finally {
            this.working = false
        }
    }
    
  },
  async mounted(){
      this.form.maxEntries = Math.ceil(this.maxTickets*0.25) || 1
  },
}
</script>