<template>
     <Flicking :options="{ align: 'prev' }" :plugins="plugins" v-if="dataReady">
      <RaffleCard v-for="raffle in raffleStore.rafflesFree" :raffle="raffle" :key="Number(raffle.id)" class="card card-hover box-shadow main-card mt-3 mb-3" />  
    <template #viewport>
    <span class="flicking-arrow-prev is-circle d-none d-lg-block"></span>
    <span class="flicking-arrow-next is-circle d-none d-lg-block"></span>
    </template>
    </Flicking>
    <Flicking :options="{ align: 'prev' }" v-else>
      <RaffleCardSkeleton v-for="index in 9" :key="index" class="card card-hover box-shadow main-card mt-3 mb-3" />
    </Flicking>
  </template>
  
  <script>
  import RaffleCard from "./RaffleCard"
  import RaffleCardSkeleton from "./RaffleCardSkeleton"
  import Flicking from "@egjs/vue3-flicking"
  import { Arrow } from "@egjs/flicking-plugins";
  import { mapStores } from 'pinia'
  import { useRaffleStore } from '@/stores/RaffleStore'
  
  export default {
    name: 'NewRafflesElement',
    props: {
      msg: String
    },
    components: {
      Flicking,
      RaffleCard,
      RaffleCardSkeleton,
    },
    data() {
      return {
      plugins: [new Arrow()],
      dataReady: false,
      }
    },
    computed: {
      ...mapStores(useRaffleStore),
    },
    async mounted(){
      this.dataReady = true;
    },
    methods: {
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
   