<template>
  <!--RaffleDetailModal-->
  <div class="modal fade" :class="{show: show}" id="raffle" aria-labelledby="#raffle"  tabindex="-1" :aria-hidden="!show" :aria-modal="show" :style="{display: show ? 'block' : 'none' }">
    <div class="modal-dialog">
      <div class="modal-content modal-border box-shadow">
        <div class="modal-header pb-075">
              <div class="card-body text-center text-truncate">
                  <h5 class="card-title text-truncate fs-1-25"><i v-if="featured === true" class="bi bi-patch-check" data-bs-toggle="tooltip" data-bs-placement="top" title="Featured Raffle"/>
                    <i v-else-if="flagged === true" class="bi bi-flag-fill text-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Flagged Raffle"/> Raffle #{{ raffle }} - <span v-show="editions > 1">{{ editions }}x </span>  {{ tokenName }} 
                    <a @click="copylink" class="pe-auto pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Raffle Link"><i class="bi bi-link-45deg  mt-2"></i></a>
                  </h5>
                  <span @click="copycontract" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Full Contract Address" class="badge bg-light badge-max-width2 text-truncate me-1"><i class="bi bi-card-image pe-1"></i> {{ ktName }}  </span>
                  <span @click="copywallet(creator)" data-bs-toggle="tooltip" data-bs-placement="top" title="Show Wallet Details" class="badge bg-secondary badge-max-width2 text-truncate"><i class="bi bi-person-fill"></i> <a :href="'https://tzkt.io/'+ `${ creator }`+'/operations/'" target="_blank" style="text-decoration: none;
    color: white;">{{ formatAddress(creator) }}</a></span>
              </div>
              <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="card-body text-center"> 
              <!-- End of Raffle Section -->
              <div v-show="raffleOver === true" class="row">
                <span class="w-100">
                    <h6 v-show="raffleOver === true" class="font-main">Raffle over</h6>
                    <video v-show="myWinningTickets.length !== 0" controls loop class="w-100 mb-1" autoplay muted>
                      <source src="Bootswatch_ Cyborg_files/images/youwon.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                    <span>
                      <button v-show="drawEpoch === '0' && totalEntries > 0 " @click="drawRaffle" type="button" class="btn btn-primary w-100 mt-2 mb-2">Draw Raffle</button>
                      <button v-show="winningTickets.length === 0 && drawEpoch !== '0' " @click="checkOutcome" type="button" class="btn btn-primary w-100 mt-2 mb-2">Check Outcome*</button>
                      <span v-show="winningTickets.length === 0 && drawEpoch !== '0'" class="small mb-4">*Takes up to 6 minutes before it can be checked.</span>
                      <!--<button @click="claimPrize" type="button" class="btn btn-primary w-100 mt-2 mb-2">Claim Prize(s)</button> -->
                    </span>
                    <div v-show="myWinningTickets.length !== 0" class="mb-4">
                      <div class="pt-2" style="max-height: 170px; overflow: auto;">
                        <span v-for="(ticketId, index) in myWinningTickets" :key="index">
                          <h6>Claim Prizes:</h6><button @click="claimPrize({ ticketId })" class="btn btn-primary m-1" ><i class="bi bi-ticket-detailed"></i> #{{ ticketId }}</button>         
                        </span>                         
                      </div>
                    </div>
                </span>
              </div>
              <div class="row">
                  <div class="col-sm-7">              
                      <div class="img-wrapper img-wrapper-grid" style="">
                        <v-lazy-image :src="`${thumbnailUrl}`" class="d-block w-100"/>
                        <span class="badge bg-light me-1" v-show="addedTezPrize > 0.01" style="position:absolute;top:25px;left:25px;"><i class="bi bi-trophy"></i> Bonus Prize: {{ addedTezPrize }} ꜩ </span>    
                      </div>  
                  </div>
                  <div class="col-sm-5 text-start pe-1 pt-3">
                      <span class="badge bg-light me-1" v-show="editions > 1"><i class="bi bi-trophy-fill"></i> Prize: {{ editions }} NFT</span>                 
                      <span class="badge bg-primary me-1"><i class="bi bi-ticket"></i> Price per Ticket: <span id="ticket-price-span">{{ ticketPrice }}</span>ꜩ</span> 
                      <span class="badge bg-primary me-1"><i class="bi bi-file-image"></i> Prize Floor: {{ floor }}ꜩ <span v-show="editions > 1">x {{ editions }} </span> </span>            
                      <span class="badge bg-secondary me-1"><i class="bi bi-ticket"></i> Raffle Vol. now: {{ (totalEntries * ticketPrice).toFixed(2)  }}ꜩ </span>
                      <span class="badge bg-secondary me-1"><i class="bi bi-ticket-fill"></i> Raffle Vol. max: {{ (maxTickets * ticketPrice).toFixed(2)  }}ꜩ </span>
                      <div class=""><a :href="`${ objktLink }`+ `${ kt }` + '/' + `${ tokenId }`" target="_blank"><i class="bi bi-box-arrow-in-up-right"></i><img src="Bootswatch_ Cyborg_files/images/objkt-white.png" style="height:13px;padding-left:5px"></a></div>
                    </div>
              </div>

                <ul class="list-group list-group-flush text-center">
                  <li class="list-group-item"></li>                                                         
                  <li class="list-group-item pt-4 pb-4">
                    <div class="row">                                                                      
                      <div class="col-md-3 pb-2">  
                          <div class="small font-main-light-italic">Tickets</div>                         
                          <input type="number" class="form-control font-main text-center" :class="validateMyEntries" id="buy-quantity" v-model="buyTicketsAmount" min="1" :max="`${myMaxEntries}`" step="1" style="height:45px;"> 
                      </div>
                      <div class="col-md-9 font-main-light-italic">
                        <div class="small">
                          <i class="bi bi-clock-history pe-1"/>
                          <span v-if="totalEntries == maxTickets">Sold-Out</span>
                          <span v-else-if="raffleOver">Raffle Over</span>
                          <span v-else>
                            <vue-countdown v-show="!raffleOver" :time="time" :interval="100" v-slot="{ days, hours, minutes, seconds }">
                                <span v-show="days > 0">{{ days }}D-</span>
                                <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H-{{ minutes }}M-{{ seconds }}S</span>
                            </vue-countdown>
                          </span>
                        </div>           
                        <button @click="buyTickets" type="button" class="btn btn-primary w-100 text-center" :class="{disabled : raffleOver && (totalEntries >= editions) , 'btn-secondary' : raffleOver && (totalEntries >= editions)}" style="height:45px;">
                          <span v-if="working" class="text-center">
                          <self-building-square-spinner style="margin: 0 auto;"
                          :animation-duration="6000"
                          :size="25"
                          :color="'#23c2cc'"
                          />
                          </span> 
                          <span v-else>Buy <span id="button-quantity">{{ buyTicketsAmount }}</span> <i class="bi bi-ticket-detailed-fill"></i> for <span id="button-volume">{{ (buyTicketsAmount * ticketPrice)  }}</span> <span class="fs-1-25">ꜩ</span></span>

                        </button>
                      </div>
                      <div class="row small text-muted text-center">  
                          <div class="col">
                              Before buying tickets, make sure to read our raffles <a href="#faqModal" target="_blank" class="text-decoration-none"><span>F.A.Q.</span></a>
                          </div>
                      </div>                         
                    </div>
                    <!-- <div class="small font-main-light mt-2" v-show="id === 115"><i class="bi bi-info-circle"></i>Raffle Creator Note: Play at Old Trafford on June 6th 2023. Travel expenses will be at ManU's discretion. Whoever holds this NFT will have access to play on the field.</div>-->
                  </li>                             
                  <div class="mt-3">
                    <ul class="nav nav-tabs font-main-italic" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button @click="switchTab('details')" :class="{active: tab==='details'}" class="nav-link" aria-selected="true" role="tab">Details</button>
                      </li>
                      <li class="nav-item" role="presentation">
                       <button @click="switchTab('entries')" :class="{active: tab==='entries'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Entries</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button @click="switchTab('winners')" :class="{active: tab==='winners'}" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Winners</button>
                      </li>
                    </ul>
                    <div id="myTabContentModal" class="tab-content text-start" style="font-size:0.9em;max-height:170px;overflow: auto">
                      <div class="tab-pane fade show active" v-if="tab === 'details'" role="tabpanel">
                        <div class="tabtab">
                              <div class="">
                                <table class="table table-hover text-sm">
                                  <tbody>
                                    <tr class="">
                                      <th scope="row"><i class="bi bi-ticket-detailed"></i> Tickets sold</th>
                                      <td><span id="details-total-entries">{{ totalEntries }}</span> / <span id="details-max-entries">{{ maxTickets }}</span></td>
                                    </tr>
                                    <tr class="">
                                      <th scope=""><i class="bi bi-ticket-detailed"></i> My tickets</th>
                                      <td><span id="details-my-entries">{{ myEntries }}</span><span class="text-primary"> +<span id="details-my-entries-preview"> {{ newTickets }}</span></span> (Max {{ myMaxEntries }})</td>
                                    </tr>
                                    <tr>                                      
                                      <th scope=""><i class="bi bi-dice-6"></i> Chance to win current</th>
                                      <td><span id="details-win-chance-current">{{ winChanceCurrent.toFixed(2) }}</span>%<span class="text-primary"> <i class="bi bi-graph-up-arrow ps-1"></i> <span id="details-win-chance-preview1">{{ winChancePreviewCurrent }}</span>%</span></td>
                                    </tr>
                                    <tr class="">
                                      <th scope=""><i class="bi bi-dice-6-fill"></i> Chance to win if sold-out</th>
                                      <td><span id="details-win-chance-soldout">{{ winChanceSoldout.toFixed(2) }}</span>%<span class="text-primary"> <i class="bi bi-graph-up-arrow ps-1"></i> <span id="details-win-chance-preview2">{{ winChancePreviewSoldout }}</span>%</span></td>
                                    </tr>                                   
                                  </tbody>
                                </table>
                              </div>                         
                          </div>   
                      </div>
                      <div class="tab-pane fade show active" v-if="tab === 'entries'" role="tabpanel">
                        <div class="tabtab">                             
                              <div class="">
                                <table class="table table-hover text-sm">
                                  <tbody>
                                    <tr v-for="ticket in allTickets" :key="ticket">
                                      <th style="min-width:175px;"><i class="bi bi-person-fill pe-1"></i><span @click="copywallet(ticket[0].address)"> {{ formatAddress(ticket[0].address) }}</span></th>
                                      <td><span v-if="ticket[1].length === 0"></span><span v-else>{{ ticket[1].length }}x</span></td>
                                      <td><span v-if="ticket[1].length === 0">Winner Ticket</span><span v-else class="small me-1"><span class="me-2" v-for="ticketNr in ticket[1] " :key="ticketNr"> <i class="bi bi-ticket-detailed me-1"></i>{{ ticketNr }} </span></span></td>
                                    </tr>                                
                                  </tbody>
                                </table>
                              </div>
                          </div>   
                      </div>
                      <div class="tab-pane fade show active" v-if="tab === 'winners'" role="tabpanel">
                        <div class="tabtab">
                              <div class="pt-2" style="height: 170px; overflow: auto;">
                                <span v-for="(ticketId, index) in winningTickets" :key="index">
                                  <button @click="claimPrize({ ticketId })" class="btn btn-primary m-1" ><i class="bi bi-ticket-detailed"></i> #{{ ticketId }}</button>         
                                </span>                         
                              </div>
                          </div>   
                      </div>                                              
                    </div>
                  </div>  
              </ul>
          </div>   
        </div> 
      </div>
    </div>
  </div>  
</template>

<script>
import { SelfBuildingSquareSpinner } from 'epic-spinners'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VLazyImage from "v-lazy-image";
import { formatPkhString } from "../utils";
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';

export default {
  name: 'ModalsRaffleDetail',
  props: {
    show: Boolean,
    raffle: Number,
  },
  components: {
    VueCountdown: VueCountdown,
    SelfBuildingSquareSpinner,
    "v-lazy-image": VLazyImage,
  },
  computed: {
    ...mapStores(useRaffleStore),

    myEntries () {
        if (typeof this.myTickets === 'undefined') return 0
        let entries = this.myTickets.length
        return entries
    },
    newTickets(){
      if (((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries) || ((Number(this.totalEntries) + this.buyTicketsAmount) > this.maxTickets)) return "Max"
      return this.buyTicketsAmount
    },
    winChancePreviewCurrent () {
        if (Number(this.totalEntries) >= this.maxTickets) return 'Max'
        if ((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries) return "Max"
        if ((this.buyTicketsAmount + Number(this.totalEntries)) > this.maxTickets) return "Err"
        return Math.min(((this.buyTicketsAmount + this.myEntries) * 100 / (Number(this.totalEntries) + this.buyTicketsAmount)) * this.editions, 100).toFixed(2)
    },
    winChancePreviewSoldout () {
       if (Number(this.totalEntries) >= this.maxTickets) return 'Max'
       if ((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries) return "Max"
       if ((this.buyTicketsAmount + Number(this.totalEntries)) > this.maxTickets) return "Err"
       return Math.min(((this.buyTicketsAmount + this.myEntries) * 100 / this.maxTickets) * this.editions, 100).toFixed(2)
    },
    winChanceCurrent () {
        if (this.totalEntries == 0) return 0
        return Math.min((this.myEntries *100 /this.totalEntries) * this.editions,100)
    },
    winChanceSoldout () {
        return Math.min((this.myEntries * 100 / this.maxTickets) * this.editions,100)     
    },
    validateMyEntries() {
      if ((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries ||(Number(this.totalEntries) + this.buyTicketsAmount) > this.maxTickets ) return 'is-invalid';
        return 'is-valid';
    }
  },
  watch: {
    raffle: {
      async handler (id) {
            if(!this.show) return
            if(typeof id === 'undefined') return
            const list = await this.raffleStore.listMyCurrentRaffleTickets(id);
            this.myTickets = list.flat(1);
            this.allTickets= await this.raffleStore.listCurrentRaffleTickets(id);
            const data = await this.raffleStore.getRaffleDetails(this.raffle);
            console.log(data);
            this.drawEpoch = data[0].draw_epoch 
            this.ticketPrice =  (data[0].ticket_price * 0.000001).toFixed(2)
            if(data[0].ticket_price* 0.000001 < 0.01){ this.ticketPrice = data[0].ticket_price* 0.000001;}  
            this.kt = data[0].kt;
            this.creator = data[0].creator;
            this.addedTezPrize = (data[0].added_prize * 0.000001).toFixed(2);
            this.editions = data[0].editions;
            this.tokenId = data[0].token_id;
            this.myMaxEntries = data[0].max_per_wallet;
            this.totalEntries = data[0].tickets_sold;
            this.maxTickets = data[0].max_tickets;
            this.winningTickets = data[0].winning_tickets; 
            this.myWinningTickets = this.myTickets.filter(element => data[0].winning_tickets.includes(element))
            // Status Checks
            if('featured' in data[0].status) {  this.featured = true  }   
            if('bad' in data[0].status) {  this.flagged = true  }   
            // RaffleOver Check
            this.raffleEnding = data[0].end_date
            const now = new Date()
            const raffleEnd = new Date(data[0].end_date)
            this.time = raffleEnd - now
            if (raffleEnd <= now) this.time = 0
            if((Date.parse(data[0].end_date ) < Date.parse(now) || this.totalEntries === this.maxTickets) && this.totalEntries > 0){
              this.raffleOver = true         
            }
            this.floor = this.raffleStore.collections[this.kt].floor
            if (this.kt === 'KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc' && this.tokenId == 6 ) this.floor = 2
            this.ktName = this.raffleStore.collections[this.kt].name
            // getTokenMetaData for IMG
            if(this.kt !== "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"){
              const meta = await this.raffleStore.getTokenMetaData({ kt: this.kt, tokenId: this.tokenId } );
              let shortlink = meta[0].metadata.thumbnailUri.substring(7)
              if (this.kt === "KT1TJ53kra5YhkmR6oPS4c7YvtkoQGZShNpe" || this.kt === "KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc") {shortlink = meta[0].metadata.displayUri.substring(7)}
              let url = "https://nftstorage.link/ipfs/"+shortlink
              if (this.kt === "KT1MQL8VjVQckk5A6uBfN9Qv2YUVJstG1CyH") { url = "https://nft-picture.playstables.io/nft/collection/001/horse/"+this.tokenId+".jpg" }
              this.thumbnailUrl = url
              this.tokenName = meta[0].metadata.name
            }    
            if(this.kt === "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"){ this.thumbnailUrl = "https://app.tezos.domains/assets/img/logo_with_white_text.svg"; this.tokenName = "Domain: celcius.tez";} 
            if(this.myWinningTickets.length !== 0) (this.startConfetti())
            },
      immediate: true
    }
  },
  methods: {
    switchTab (tab) {
        this.tab = tab
    }, 
    startConfetti() {
        this.$confetti.start();
      },
    // Raffle Interactions
    async buyTickets () {
        try {
            this.working = true
            this.raffleStore.buyTickets({ quantity: this.buyTicketsAmount, raffleId: this.raffle, ticketPrice: this.ticketPrice })
            await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
            console.log(e)
        } finally {
            this.working = false
        }
    },
    drawRaffle () {
        try {
            this.working = true
            this.raffleStore.drawRaffle({ raffleId: this.raffle })
        } catch (e) {
            console.log(e)
        } finally {
            this.working = false
        }
    },  
    checkOutcome () {
        try {
            this.working = true
            this.raffleStore.checkOutcome({ raffleId: this.raffle })
        } catch (e) {
            console.log(e)
        } finally {
            this.working = false
        }
    },  
    claimPrize (winnerTicketId) {
        try {
            this.working = true
            // console.log(winnerTicketId['ticketId'])
            this.raffleStore.claimPrize({ raffle_id: this.raffle, ticket_id: Number(winnerTicketId['ticketId'])})
        } catch (e) {
            console.log(e)
        } finally {
            this.working = false
        }
    },  
    // copy current raffle link function
    copylink(){
      navigator.clipboard.writeText(window.location.href);
      return false
    },
    // copy raffle wallet function
    copywallet(wallet){
      navigator.clipboard.writeText(wallet);
      return false
    },
    // copy contract function
    copycontract(){
      navigator.clipboard.writeText(this.kt);
      return false
    },    
    // return tez.domain or shorten wallets
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse ( JSON.stringify ( this.raffleStore.wallets) )
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    }, 
  },
  data () {
    return {
        optional: false,
        working: false,
        tab: 'details',
        kt: '',
        myTickets: [],        
        ktName: '',
        drawEpoch: 0,
        creator: '',
        tokenName: '',
        tokenId: 0,
        ticketPrice: 1,
        entriesValue: 0,
        entriesValueMax: 0,
        objktLink: "https://objkt.com/asset/",
        thumbnailUrl: '',
        buyTicketsAmount: 1,
        totalEntries: 0,
        maxEntries: 0,
        myMaxEntries: 0,
        maxTickets: 0,
        editions: 1,
        maxEntriesPercent: 0,
        raffleEnding: 0,
        allTickets: [],
        winningTickets: [],
        myWinningTickets: [],
        featured: false,
        flagged: false,
        raffleOver: false,
        addedTezPrize: 0,
        floor: 0,
        time: 0
        }
      },
}
</script>

<style scoped>
.np-ht {
  height: 600px;
}

.np-header {
  padding: 30px;
  background: #eee;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.v-lazy-image {
  filter: blur(20px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>